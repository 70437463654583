import React, { Component } from 'react';
import { string, func, bool, shape } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, SIZE_TYPE, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useDispatch, useSelector } from 'react-redux';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { compose } from 'redux';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
export const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    history,
    showAuthorInfo,
    location,
    CategorySelected,
  } = props;
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } =
    (!!currentListing.id && currentListing.attributes) || {};
  const {quantity} = currentListing?.currentStock?.attributes || {} ; 
  const { size,customSize, listingVideoAndImagesUrl } = publicData || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const storeType = publicData?.listingStore;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const { favouriteItems = [] } =
    (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};

  const index = favouriteItems && favouriteItems.findIndex(e => e == currentListing.id.uuid);

  const handleFavouriteItems = () => {
    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      if (index > -1) {
        favouriteItems.splice(index, 1);
        const addedfavouriteItems = Array.from(new Set(favouriteItems));
        const profile = {
          protectedData: {
            favouriteItems: addedfavouriteItems,
          },
        };
        dispatch(updateProfile(profile));
      } else {
        favouriteItems.push(currentListing.id.uuid);
        const addedfavouriteItems = Array.from(new Set(favouriteItems));
        const profile = {
          protectedData: {
            favouriteItems: addedfavouriteItems,
          },
        };
        dispatch(updateProfile(profile));
      }
    }
  };

  return (
    <NamedLink
      className={classes}
      name="ListingPage"
      params={{ id, slug }}
      to={{ storeType: CategorySelected }}
    >
      <AspectRatioWrapper
        className={classNames(
          css.aspectRatioWrapper,
          (quantity === 0 && css.itemSold),
          storeType == 'sneakers'
            ? css.sneakerBorder
            : storeType == 'streetwear'
            ? css.streetwearBorder
            : storeType == 'vintage'
            ? css.vintageBorder
            : storeType == 'clothing'
            ? css.clothingBorder
            : null
        )}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          listingVideoAndImagesUrl={listingVideoAndImagesUrl}
          handleFavouriteItems={handleFavouriteItems}
          variants={variants}
          isAuthenticated={isAuthenticated}
          index={index}
          isListingCards={true}
          sizes={renderSizes}
          currentStock={quantity}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              <span className={css.priceValueCurrency}>
                {formattedPrice} <span className={css.currency}>{price?.currency}</span>{' '}
              </span>
              <span className={css.signgleLine}></span>
              <span className={css.priceValueSize}>
                <FormattedMessage id="OrderPanel.sizeText" /> {size === SIZE_TYPE ? customSize :size}
              </span>
            </div>
            {config.listing.showUnitTypeTranslations ? (
              <div className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            ) : null}
          </div>
          {/* {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null} */}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};
const ListingCard = compose(withRouter, injectIntl)(ListingCardComponent);

export default ListingCard;
