import config from '../../config';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  getBlogs,
  getHeroSectionData,
  getHeroSectionSneakersData,
  getHeroSectionStreetwearData,
  getHeroSectionVintageData,
} from '../../util/api';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { ALL_GENDER, FOR_SALE } from '../../util/types';
import { parse } from '../../util/urlHelpers';
export const ASSET_NAME = 'landing-page';

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const GET_BLOG_SUCCESS = 'app/LandingPage/GET_BLOG_SUCCESS';
export const RECENTLY_VIEW_LISTINGS_SUCCESS = 'app/SearchPage/RECENTLY_VIEW_LISTINGS_SUCCESS';

const RESULT_PAGE_SIZE = 60;

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  currentPageRecentResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  blogsData: [],
  heroSectionData: [],
  pageInProgress: false,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
        pageInProgress: true,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
        pageInProgress: false,
      };
    case RECENTLY_VIEW_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageRecentResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: payload,
        pageInProgress: false,
      };
    case GET_BLOG_SUCCESS:
      return {
        ...state,
        blogsData: payload.data,
        heroSectionData: payload.result,
      };
    default:
      return state;
  }
};
export default listingPageReducer;

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const recentViewListingsSuccess = response => ({
  type: RECENTLY_VIEW_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const getBlogSuccess = (data, result) => ({
  type: GET_BLOG_SUCCESS,
  payload: { data, result },
});

export const recentlyViewListings = (params, search, searchParams) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    // No need to listing data when creating a new listing
    return Promise.all([dispatch(fetchCurrentUser())])
      .then(async response => {
        const currentUser = getState().user.currentUser;
        if (currentUser && currentUser.id) {
          const currentUser = getState().user.currentUser;
          const { recentlyViewedItems } = currentUser.attributes.profile.publicData || {};

          // Extract all id values into a single array
          const allIds = recentlyViewedItems?.map((e) => e.id)

          const response =
            recentlyViewedItems && (await sdk.listings.query({ ids: allIds,perPage: 60, ...searchParams }));
          if (response) {
            const data = response.data.data?.forEach((data)=>{
              const {publicData} = data.attributes || {};
              const dateTime = recentlyViewedItems.find((e)=>e.id === data.id.uuid);
             return Object.assign(publicData, {
               updatedTime:dateTime.date,
              });
              
            })    
            dispatch(addMarketplaceEntities(response));
            dispatch(recentViewListingsSuccess(response));
            return response;
          }
        }
      })
      .catch(e => {
        throw e;
      });
  } catch (error) { }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  try {
    const listingSearchParams = parse(search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // Add minStock filter with default value (1), if stock management is in use.
    // This can be overwriten with passed-in query parameters.
    const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
    const { page = 1, address, origin, ...rest } = listingSearchParams;
    const originMaybe = isOriginInUse(config) && origin ? { origin } : {};
    const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
    const aspectRatio = aspectHeight / aspectWidth;
    const searchParams = {
      // ...minStockMaybe,
      ...rest,
      ...originMaybe,
      page,
      pub_gender: params.gender === ALL_GENDER ? null : params.gender,
      pub_listingStore: params.type,
      include: ['author', 'images', 'currentStock'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    };
    dispatch(searchListingsRequest(searchParams));
    const response = await sdk.listings.query({...searchParams,perPage: 24,});

    dispatch(addMarketplaceEntities(response));
    // dispatch(searchListingsSuccess(response));
    const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };


    if (typeof window !== 'undefined') {
      const { blogData } = await getBlogs({});

      const configResponse = await Promise.all([
        getHeroSectionData(),
        getHeroSectionSneakersData(),
        getHeroSectionStreetwearData(),
        getHeroSectionVintageData(),
        dispatch(fetchPageAssets(pageAsset, true)).then(r => {
          dispatch(searchListingsSuccess(response));
        }),
        dispatch(recentlyViewListings(params, search, searchParams)),
      ]);

      const configOptions = {
        clothing: configResponse[0].heroSectionClothingData,
        sneakers: configResponse[1].heroSectionSneakersData,
        streetwear: configResponse[2].heroSectionStreetwearData,
        vintage: configResponse[3].heroSectionVintageData,
      };
      dispatch(getBlogSuccess(blogData, configOptions));
    }
  } catch (error) {
    console.log(error, '&&&  &&& => error');
  }
};
